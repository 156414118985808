type localStorageEmail = { email: string; lastUpdate: Date }

export function registerEmailToLocalStorage(email: string) {
  const obj = { email, lastUpdate: new Date().toISOString() }
  localStorage.setItem('user', JSON.stringify(obj))
}

export function getEmailFromLocalStorage(): localStorageEmail {
  const localStorageString = localStorage.getItem('user')

  if (!localStorageString) return { email: null, lastUpdate: null }
  return JSON.parse(localStorageString)
}
