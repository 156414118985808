import Lenis from '@studio-freight/lenis'
import { createContext, useContext, useEffect, useState } from 'react'

const LenisContext = createContext(null)

export const useLenis = (): Lenis => useContext(LenisContext)

export const LenisProvider = ({ children }) => {
  const [contextValue, setContextValue] = useState<Lenis>(null)

  useEffect(() => {
    const lenis = new Lenis(defaultLenisConfig)
    setContextValue(lenis)

    let af

    function raf(time) {
      lenis.raf(time)
      af = window.requestAnimationFrame(raf)
    }

    window.requestAnimationFrame(raf)

    return () => {
      lenis.destroy()
      window.cancelAnimationFrame(af)
    }
  }, [])

  return (
    <LenisContext.Provider value={contextValue}>
      {children}
    </LenisContext.Provider>
  )
}

interface ILenisConfig {
  duration: number
  easing?: (t: number) => number
  direction: 'vertical' | 'horizontal'
  gestureDirection: 'vertical' | 'horizontal' | 'both'
  smooth: boolean
  mouseMultiplier: number
  smoothTouch: boolean
  touchMultiplier: number
  infinite: boolean
}

export const defaultLenisConfig: ILenisConfig = {
  duration: 0.4,
  // easing: t => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
  direction: 'vertical', // vertical, horizontal
  gestureDirection: 'vertical', // vertical, horizontal, both
  smooth: true,
  mouseMultiplier: 1,
  smoothTouch: false,
  touchMultiplier: 2,
  infinite: false,
}
