import { m } from 'framer-motion'
import { useEffect, useRef } from 'react'

export default function GlyphAnimationLoader({ chars = '/–\\|' }) {
  const ref = useRef(null)

  useEffect(() => {
    let fid = window.requestAnimationFrame(cb)
    let idx = 0
    let elapsed = 0

    function cb() {
      fid = window.requestAnimationFrame(cb)
      elapsed++

      if (elapsed === 15) elapsed = 0
      if (elapsed > 0) return

      if (!ref.current) return
      ref.current.innerText = chars[idx]
      idx = idx + 1 > chars.length - 1 ? 0 : idx + 1
    }

    return () => {
      window.cancelAnimationFrame(fid)
    }
  }, [])

  return (
    <m.div
      initial="initial"
      animate="animate"
      exit="exit"
      // variants={contentVariants}
      className="absolute inset-0 text-center z-50 flex justify-center items-center pointer-events-none"
    >
      <m.div ref={ref} className="text-4xl" />
    </m.div>
  )
}
