import { IProduct } from '@type/shopify/product'

import { getEmailFromLocalStorage } from '@lib/storage'

export async function trackKlaviyoPageView(opts: {
  url: string
  pathname: string
}) {
  const { email } = getEmailFromLocalStorage()
  if (!email) return

  const body = JSON.stringify({ ...opts, email })

  await fetch('/api/track/page_view', { method: 'POST', body })
}

export async function trackKlaviyoContentView(product: IProduct) {
  const { id, handle, title, priceRange, product_area, productType } = product
  const { email } = getEmailFromLocalStorage()
  if (!email) return

  const body = JSON.stringify({
    product: {
      id,
      handle,
      title,
      value: priceRange.minVariantPrice.amount,
      product_area,
      productType,
    },
    email,
  })

  await fetch('/api/track/content_view', { method: 'POST', body })
}

export async function trackKlaviyoAddToCart(product: any) {
  const { id, handle, title, priceRange, selected } = product
  const { email } = getEmailFromLocalStorage()
  if (!email) return

  const body = JSON.stringify({
    email,
    product: {
      id,
      handle,
      title,
      value: priceRange.minVariantPrice.amount,
      selected,
    },
  })

  await fetch('/api/track/add_to_cart', { method: 'POST', body })
}
