import { buildClient } from 'shopify-buy'

import {
  createNewShopifyCustomer,
  fetchSitemap,
  getAllProductCategories,
  getAllProductTypes,
  getAllProducts,
  getFeaturedProducts,
  getPrivacyPolicy,
  getShopInformation,
  getSingleProduct,
  getVariantBySelectedOptions,
  getVariantsOfProduct,
} from './shop'

const domain = process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN
const token = process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESSTOKEN
const API_VERSION = '2024-04'

export const shopifyClient = buildClient(
  { domain, storefrontAccessToken: token, apiVersion: API_VERSION },
  fetch as any
)

/**
 * query data from Shopify Storefront API
 * @param query
 * @param variables
 */
export async function shopifyQuery(query, variables = undefined) {
  const URL = `https://${domain}/api/${API_VERSION}/graphql.json`

  const options = {
    endpoint: URL,
    method: 'POST',
    headers: {
      'X-Shopify-Storefront-Access-Token': token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query, variables }, null, 2),
  }

  try {
    const data = await fetch(URL, options).then(response => {
      return response.json()
    })

    return data
  } catch (error) {
    throw new Error(`Products not fetched. ${error}`)
  }
}

export {
  createNewShopifyCustomer,
  fetchSitemap,
  getAllProductCategories,
  getAllProductTypes,
  getAllProducts,
  getFeaturedProducts,
  getPrivacyPolicy,
  getShopInformation,
  getSingleProduct,
  getVariantBySelectedOptions,
  getVariantsOfProduct,
}
