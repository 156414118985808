// import { init } from '@fullstory/browser'
import { IProduct } from '@type/shopify/product'
import ShopifyBuy from 'shopify-buy'

import { trackKlaviyoPageView } from '../klaviyo'
import {
  trackKlaviyoAddToCart,
  trackKlaviyoContentView,
} from '../klaviyo/client'
import {
  initPixel,
  trackPixelAddToCart,
  trackPixelContentView,
  trackPixelInitiatedCheckout,
  trackPixelPageView,
} from './pixel'

import { DEV } from '@lib/const'

// export const fullStoryConfig = { orgId: 'o-1BQWEC-na1', devMode: DEV }

export async function initTrackers() {
  // if (DEV) return
  // init(fullStoryConfig)
  initPixel()
}

export async function trackPageView(obj: { url: string; pathname: string }) {
  // if (DEV) return
  trackPixelPageView()
  trackKlaviyoPageView(obj)
}

export async function trackContentView(obj: IProduct) {
  // if (DEV) return
  trackPixelContentView(obj)
  trackKlaviyoContentView(obj)
}

export async function trackAddToCart(obj: any) {
  // if (DEV) return
  trackPixelAddToCart(obj)
  trackKlaviyoAddToCart(obj)
}

export async function trackInitiatedCheckout(cart: ShopifyBuy.Checkout) {
  // if (DEV) return
  trackPixelInitiatedCheckout(cart)
  // trackKlaviyoInitiatedCheckout()
}
