const crypto = require('crypto')

export function easeInOutExpo(x) {
  return x === 0
    ? 0
    : x === 1
    ? 1
    : x < 0.5
    ? Math.pow(2, 20 * x - 10) / 2
    : (2 - Math.pow(2, -20 * x + 10)) / 2
}

export function trueVh() {
  if (typeof window === 'undefined') return
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

/**
 * Random Password Generator
 * https://www.techengineer.one/random-password-generator-in-node-js/
 */
const PASSWORD_LENGTH = 18
const LOWERCASE_ALPHABET = 'abcdefghijklmnopqrstuvwxyz' // 26 chars
const UPPERCASE_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' // 26 chars
const NUMBERS = '0123456789' // 10 chars
const SYMBOLS = ',./<>?;\'":[]\\|}{=-_+`~!@#$%^&*()' // 32 chars
const ALPHANUMERIC_CHARS = LOWERCASE_ALPHABET + UPPERCASE_ALPHABET + NUMBERS // 62 chars
const ALL_CHARS = ALPHANUMERIC_CHARS + SYMBOLS // 94 chars

export function generateRandomPassword(
  length = PASSWORD_LENGTH,
  alphabet = ALL_CHARS
) {
  var rb = crypto.randomBytes(length)
  var rp = ''

  for (var i = 0; i < length; i++) {
    rb[i] = rb[i] % alphabet.length
    rp += alphabet[rb[i]]
  }

  return rp
}

/**
 * Checks if two arrays of objects have the same values
 * @param arr1
 * @param arr2
 * @returns
 */
export function arraysEqual(arr1: any[], arr2: any[]) {
  if (arr1.length !== arr2.length) return false
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].name !== arr2[i].name || arr1[i].value !== arr2[i].value) {
      return false
    }
  }
  return true
}
