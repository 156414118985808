import { FaviconTags, GLOBAL_TAGS } from '@/lib/const'
import Head from 'next/head'
import { renderMetaTags } from 'react-datocms/seo'

const GlobalMetaTags = ({ data = GLOBAL_TAGS }: { data?: any }) => {
  return (
    <Head>
      {renderMetaTags(data)}
      <FaviconTags />
    </Head>
  )
}

export default GlobalMetaTags
