import { Product, ProductVariant } from 'shopify-buy'

import { shopifyQuery } from '.'

import { generateRandomPassword } from '@lib/utils'

export async function getFeaturedProducts() {
  const query = `
    {
      collection(handle: "spotlight") {
        products(first: 3) {
          nodes {
            handle
            title
            availableForSale
            priceRange {
              minVariantPrice {
                amount
                currencyCode
              }
            }
            images(first: 2) {
              nodes {
                url(transform: {maxHeight: 960, maxWidth: 960})
                width
                height
              }
            }
          }
        }
      }
    }
  `

  const response = await shopifyQuery(query)

  return response.data.collection.products.nodes
}

/**
 * @param email the new subscriber's email
 */
export async function createNewShopifyCustomer({ email }) {
  const query = `
    mutation customerCreate($input: CustomerCreateInput!) {
      customerCreate(input: $input) {
        customer {
          email
          acceptsMarketing
        }
        customerUserErrors {
          field
          message
          code
        }    
      }
    }
  `

  const password = generateRandomPassword(32)
  const payload = {
    input: {
      email,
      password,
      acceptsMarketing: true,
    },
  }

  const response = await shopifyQuery(query, payload)

  return response
}

/**
 * query to get the shop info and branding from Shopify Storefront API
 */
export async function getShopInformation() {
  const query = `
      query ShopBranding {
        shop {
          brand {
            shortDescription
            slogan
            logo {
              image {
                url
                width
                height
              }
            }
            coverImage {
              image {
                url
                width
                height
              }
              alt
            }
          }
        }
      }
    `

  const response = await shopifyQuery(query)
  const shopInfo = response.data.shop ?? []

  return shopInfo
}

/**
 * query to get all products from Shopify Storefront API
 */
export async function getAllProducts(): Promise<Product[]> {
  const query = `
    {
      collection(handle: "home") {
        products(first: 100, sortKey: RELEVANCE) {
          nodes {
            availableForSale
            id
            title
            handle
            productType
            priceRange {
              minVariantPrice {
                amount
                currencyCode
              }
            }
            options {
              name
              values
            }
            images(first: 10) {
              nodes {
                url
                altText
                width
                height
                lowRes: url(transform: {maxHeight: 8})
              }
            }
            tags
          }
        }
      }
    }
  `

  const response = await shopifyQuery(query)
  const allProducts = response.data.collection.products.nodes ?? []
  // const allProductsSorted = customSortByProductType(allProductsFormatted)

  return allProducts
}

/**
 * query to get a single product from Shopify Storefront API
 * @param handle
 */
export async function getSingleProduct(handle: string): Promise<Product> {
  const query = `
  {
    product: productByHandle(handle: "${handle}") {
      id
      title
      description
      descriptionHtml
      handle
      seo {
        description
        title
      }
      productType
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
      }
      availableForSale
      options {
        id
        name
        values
      }
      variants(first: 30) {
        nodes {
          id
          title
          availableForSale
          selectedOptions {
            name
            value
          }
          image {
            url
            lowRes: url(transform: {maxHeight: 8})
          }
        }
      }
      featuredImage {
        url
        altText
        width
        height
        lowRes: url(transform: {maxHeight: 8})
      }
      images(first: 20) {
        nodes {
          url
          altText
          width
          height
          lowRes: url(transform: {maxHeight: 8})
        }
      }
      tags
      product_area: metafield(namespace: "custom", key: "product_area") {
        value
      }
    }
  }
`

  const response = await shopifyQuery(query)
  const product = formatProduct(response.data.product)

  function formatProduct(product) {
    return { ...product, images: product.images.nodes.map(n => n) }
  }

  return product
}

/**
 * query to get a single product from Shopify Storefront API
 * @param handle
 */
export async function getVariantsOfProduct(
  handle: string,
  first = 30
): Promise<ProductVariant[]> {
  const query = `
  {
    product: productByHandle(handle: "${handle}") {
      variants(first: ${first}) {
        nodes {
          id
          title
          availableForSale
          selectedOptions {
            name
            value
          }  
        }
      }
    }
  }
`

  const response: any = await shopifyQuery(query)
  const variants = response.data.product.variants.nodes
  return variants
}

/**
 * query to get a single product from Shopify Storefront API
 * @param handle the unique handle of the product
 * @param options the array of options
 */
export async function getVariantBySelectedOptions({
  handle,
  options,
}: {
  handle: string
  options: any
}) {
  const query = `
    query SingleProductQuery($handle: String, $selectedOptions: [SelectedOptionInput!]!) {
      product(handle: $handle) {
        variantBySelectedOptions(selectedOptions: $selectedOptions) {
          id
          availableForSale
          title
        }
      }
    }
  `

  const variables = {
    handle: handle,
    selectedOptions: options,
  }

  const { data } = await shopifyQuery(query, variables)
  const variant = data?.product.variantBySelectedOptions ?? null

  return variant
}

/**
 * query to get all the tags used in the shop
 */
export async function getAllProductTypes() {
  const query = `
    query ProductTagsQuery {
      productTypes(first: 250) {
        edges {
          node
        }
      }
    }
  `

  const response = await shopifyQuery(query)
  const tags = response.data.productTypes.edges.map((e: any) => e.node)

  return tags
}

// /**
//  * query to get all the product collections used in the shop
//  */
// export async function getAllCollections(): Promise<NavHandleObject[]> {
//   const query = `
//     query ProductTagsQuery {
//       collections(first: 25) {

//         nodes {
//           handle
//           name: title
//         }
//       }
//     }
//   `

//   const response = await shopifyQuery(query)
//   const collections = response.data.collections.nodes.filter(
//     (c: NavHandleObject) => c.handle !== 'home'
//   )

//   return collections
// }

export async function getAllProductCategories(first = 25) {
  const query = `
    query AllProductTypesQuery($first: Int!) {
      productTypes(first: $first) {
        edges {
          node
        }
      }
    }
`

  const response = await shopifyQuery(query, { first })
  const collections = response.data.productTypes.edges.map((n: any) => ({
    name: n.node,
    handle: n.node.toLowerCase(),
  }))

  return collections
}

/**
 * Get the shop's privacy policy text in HTML format, along with its title and handle
 * @returns an object with the handle, the title and the body of the policy text
 */
export async function getPrivacyPolicy() {
  const query = `
    query PrivacyPolicy {
      shop {
        privacyPolicy {
          handle
          title
          body
        }
      }
    }
`

  const response = await shopifyQuery(query)
  const data = response.data.shop.privacyPolicy

  return data
}

/**
 * Get the shop's privacy policy text in HTML format, along with its title and handle
 * @returns an object with the handle, the title and the body of the policy text
 */
export async function getRefundPolicy() {
  const query = `
    query RefundPolicy {
      shop {
        refundPolicy {
          handle
          title
          body
        }
      }
    }
`

  const response = await shopifyQuery(query)
  const data = response.data.shop.refundPolicy

  return data
}

/**
 * Get the shop's privacy policy text in HTML format, along with its title and handle
 * @returns an object with the handle, the title and the body of the policy text
 */
export async function getTermsOfService() {
  const query = `
    query TermsOfService {
      shop {
        termsOfService {
          handle
          title
          body
        }
      }
    }
`

  const response = await shopifyQuery(query)
  const data = response.data.shop.termsOfService

  return data
}

// function populateSeoIfMissing(collectionData: any) {
//   const { seo, title, description, products } = collectionData
//   const { title: seoTitle, description: seoDescription } = seo

//   const image = products.nodes[0]?.images.nodes[0].url ?? ''

//   return {
//     title: seoTitle || title,
//     description: seoDescription || description,
//     image,
//   }
// }

/**
 * query to get all products from Shopify Storefront API
 */
export async function fetchSitemap(): Promise<any> {
  const query = `
    {
      products(first:100){
        nodes{
          handle
        }
      }
    }
  `

  const response = await shopifyQuery(query)

  return {
    products: response.data.products.nodes,
  }
}
