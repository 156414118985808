import ShopifyBuy, { Product } from 'shopify-buy'

// const advancedMatching = { em: '' } // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const pixelOptions = {
  autoConfig: false, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
}

export async function getPixel() {
  return (await import('react-facebook-pixel')).default
}

export async function initPixel() {
  const px = await getPixel()
  px.init(process.env.NEXT_PUBLIC_PIXEL_ID, null, pixelOptions)
  px.pageView()

  return px
}

export async function trackPixelPageView() {
  const px = await getPixel()
  px.pageView()
}

export async function trackPixelContentView(product: any) {
  const px = await getPixel()
  const { id, title, productType, priceRange } = product

  const props = {
    content_ids: id,
    content_name: title,
    content_category: productType,
    content_type: productType,
    contents: { ...product },
    currency: priceRange.minVariantPrice.currencyCode,
    value: priceRange.minVariantPrice.amount,
  }

  px.track('ViewContent', props)
}

export async function trackPixelAddToCart(product: Product) {
  const px = await getPixel()
  // px.view

  const { id, title, productType, priceRange } = product

  const props = {
    content_ids: id,
    content_name: title,
    content_type: productType,
    contents: { ...product },
    currency: priceRange.minVariantPrice.currencyCode,
    value: priceRange.minVariantPrice.amount,
  }
  px.track('AddToCart', props)
}

export async function trackPixelInitiatedCheckout(cart: ShopifyBuy.Checkout) {
  const px = await getPixel()

  const { lineItems, lineItemsSubtotalPrice } = cart

  const contents = lineItems.map(({ id, title, variant }) => ({
    id,
    title,
    value: variant.price.amount,
    currency: variant.price.currencyCode,
    selectedOptions: variant.selectedOptions,
  }))

  const props = {
    content_ids: lineItems.map(itm => itm.id),
    contents: contents,
    currency: lineItemsSubtotalPrice.currencyCode,
    num_items: lineItems.length,
    value: lineItemsSubtotalPrice.amount,
  }

  px.track('InitiateCheckout', props)
}
