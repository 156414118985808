import { Product, SelectedOption } from 'shopify-buy'

import { trackKlaviyoPageView } from './client'
import { klaviyoSubscribeNewsletter, klaviyoSubscribeSovrumana } from './server'

export type klaviyoEventKey =
  | 'page_view'
  | 'content_view'
  | 'add_to_cart'
  | 'checkout'

export type ProductTrackingInformation = {
  id: Product['id']
  handle: Product['handle']
  title: Product['title']
  priceRange: Product['priceRange']
  selected: SelectedOption
}

export {
  trackKlaviyoPageView,
  klaviyoSubscribeNewsletter,
  klaviyoSubscribeSovrumana,
}
