import { AnimatePresence, Transition, Variants, m } from 'framer-motion'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { initTrackers, trackPageView } from '@lib/analytics'

function getOneWeekFromNow() {
  const today = new Date()
  const inOneWeek = today.setDate(today.getDate() + 7)
  return inOneWeek
}

const CookieConsent = () => {
  const router = useRouter()
  const [showBanner, setShowBanner] = useState<boolean>(false)

  async function acceptConsent() {
    const inOneWeek = getOneWeekFromNow()
    const consentOptions = { consent: true, expires: inOneWeek }

    localStorage.setItem('cookieConsent', JSON.stringify(consentOptions))

    function registerPageViewOnRouteChange() {
      router.events.on('routeChangeComplete', trackPageView)
    }

    setShowBanner(false)

    initTrackers()
    registerPageViewOnRouteChange()
  }

  function rejectConsent() {
    const inOneWeek = getOneWeekFromNow()
    const consentOptions = { consent: false, expires: inOneWeek }

    localStorage.setItem('cookieConsent', JSON.stringify(consentOptions))

    setShowBanner(false)
  }

  // Tracking
  useEffect(() => {
    const consent = JSON.parse(localStorage.getItem('cookieConsent'))

    if (consent === null) return setShowBanner(true)

    const isConsentExpired =
      new Date().getDate() - new Date(consent.expires).getDate() >= 7

    if (isConsentExpired) {
      const inOneWeek = getOneWeekFromNow()
      const consentOptions = { consent: false, expires: inOneWeek }
      localStorage.setItem('cookieConsent', JSON.stringify(consentOptions))

      setShowBanner(true)
      return
    }

    function registerPageViewOnRouteChange() {
      router.events.on('routeChangeComplete', trackPageView)
    }

    initTrackers()
    registerPageViewOnRouteChange()
  }, [])

  return (
    <AnimatePresence>
      {showBanner && (
        <>
          <m.div
            key="overlay"
            className="fixed inset-0 bg-black z-[9998]"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={overlayVariants}
            transition={transition}
          />
          <m.div
            key="content"
            initial="initial"
            animate="animate"
            exit="exit"
            className="fixed z-[9999] bottom-0 left-0"
            style={{ perspective: '300px' }}
          >
            <m.div
              className="max-w-lg m-2 p-4 bg-background text-foreground shadow-2xl"
              variants={contentVariants}
              transition={transition}
            >
              <p className="mb-4">
                We only use tracking technologies to improve our service to you.
                You may opt in or opt out of the use of these technologies.
              </p>
              <div className="flex justify-end gap-6">
                <button
                  className="px-4 py-2 text-foreground active:opacity-50"
                  onClick={rejectConsent}
                >
                  No, thanks
                </button>
                <button
                  className="px-4 py-2 bg-foreground text-background active:bg-background active:text-foreground"
                  onClick={acceptConsent}
                >
                  Sounds good
                </button>
              </div>
            </m.div>
          </m.div>
        </>
      )}
    </AnimatePresence>
  )
}

const transition: Transition = {
  type: 'ease',
  ease: [0.1, 0, 0, 0.9],
  duration: 0.45,
}

const contentVariants: Variants = {
  initial: { opacity: 0, y: '40%', rotateX: 12 },
  animate: {
    opacity: 1,
    y: '0%',
    rotateX: 0,
    transition: { delay: 0.5, ...transition },
  },
  exit: { opacity: 0, y: '40%', rotateX: 0 },
}

const overlayVariants: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 0.5 },
  exit: { opacity: 0 },
}

export default CookieConsent
