import { ProductTrackingInformation } from '.'

import { DEV } from '@lib/const'

const KLAVIYO_URL = 'https://a.klaviyo.com/client/events/?company_id=VfXaYe'

const METHOD = 'post'
const DEFAULT_HEADERS = {
  accept: 'application/json',
  revision: '2024-06-15',
  'content-type': 'application/json',
  Authorization: `Klaviyo-API-Key ${process.env.KLAVIYO_API_KEY}`,
}

async function klaviyoFetch(options: RequestInit) {
  let out = null

  if (DEV) return

  try {
    await fetch(KLAVIYO_URL, options).then(r => r.json())
    // .then(console.log)
  } catch (e) {
    // console.error(e)
    out = e
  }

  return out
}

export async function klaviyoSubscribeSovrumana({ email, handle }) {
  const fetchOptions = {
    method: METHOD,
    headers: DEFAULT_HEADERS,
    body: JSON.stringify({
      data: {
        type: 'event',
        attributes: {
          data: {
            type: 'profile',
            attributes: { email, instagram_handle: handle },
          },
          metric: {
            data: {
              type: 'metric',
              attributes: { name: 'Clicked Subscribe Button' },
            },
          },
          time: new Date(),
          properties: { email: email, instagram_handle: handle },
          // value: 9.99,
          // unique_id: 'sovrumana_celestialis',
        },
      },
    }),
  }

  if (!email) return
  return await klaviyoFetch(fetchOptions)
}

export async function klaviyoSubscribeNewsletter({ email }) {
  const fetchOptions = {
    method: METHOD,
    headers: DEFAULT_HEADERS,
    body: JSON.stringify({
      data: {
        type: 'event',
        attributes: {
          profile: {
            data: { type: 'profile', attributes: { email } },
          },
          metric: {
            data: {
              type: 'metric',
              attributes: { name: 'Clicked Newsletter Subscribe Button' },
            },
          },
          time: new Date(),
          properties: { email: email },
          // value: 9.99,
          // unique_id: 'sovrumana_celestialis',
        },
      },
    }),
  }

  if (!email) return
  return await klaviyoFetch(fetchOptions)
}

export async function klaviyoReportPageView({ url, pathname, email }) {
  const fetchOptions = {
    method: METHOD,
    headers: DEFAULT_HEADERS,
    body: JSON.stringify({
      data: {
        type: 'event',
        attributes: {
          metric: {
            data: { type: 'metric', attributes: { name: 'Viewed page' } },
          },
          time: new Date(),
          properties: { email, url, pathname },
          // customer_properties: { email },
          // unique_id: 'sovrumana_celestialis',
          profile: {
            data: {
              type: 'profile',
              attributes: { email },
            },
          },
        },
      },
    }),
  }

  if (!email) return
  return await klaviyoFetch(fetchOptions)
}

export async function klaviyoReportContentView({
  product,
  email,
}: {
  product: any
  email: string
}) {
  const fetchOptions = {
    method: METHOD,
    headers: DEFAULT_HEADERS,
    body: JSON.stringify({
      data: {
        type: 'event',
        attributes: {
          metric: {
            data: { type: 'metric', attributes: { name: 'Viewed content' } },
          },
          time: new Date(),
          properties: { product },
          // customer_properties: { email },
          // unique_id: 'sovrumana_celestialis',
          profile: {
            data: {
              type: 'profile',
              attributes: { email },
            },
          },
        },
      },
    }),
  }

  if (!email) return
  return await klaviyoFetch(fetchOptions)
}

export async function klaviyoReportAddToCart({
  product,
  email,
}: {
  product: ProductTrackingInformation
  email: string
}) {
  const fetchOptions = {
    method: METHOD,
    headers: DEFAULT_HEADERS,
    body: JSON.stringify({
      data: {
        type: 'event',
        attributes: {
          metric: {
            data: { type: 'metric', attributes: { name: 'Added to cart' } },
          },
          time: new Date(),
          properties: product,
          // customer_properties: { email },
          // unique_id: 'sovrumana_celestialis',
          profile: {
            data: {
              type: 'profile',
              attributes: { email },
            },
          },
        },
      },
    }),
  }

  if (!email) return
  return await klaviyoFetch(fetchOptions)
}
