// import Footer from '@components/molecules/footer'
import { useRouter } from 'next/router'
import { ReactNode, useEffect } from 'react'

import { LenisProvider, useLenis } from '@lib/lenisContext'

type Props = {
  children?: ReactNode
}

export default function SmoothScroll({ children }: Props) {
  const router = useRouter()
  const lenis = useLenis()

  useEffect(() => {
    const hash = new URL(router.asPath, 'https://capslockmagazine.eu').hash

    if (hash && lenis) {
      lenis.scrollTo(hash, {
        immediate: true,
      })
    }
  }, [lenis])

  return <LenisProvider>{children}</LenisProvider>
}
