import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useState } from 'react'

interface ITransitionContext {
  isTransitioning: boolean
}

const pageTransitionContext = createContext<ITransitionContext>({
  isTransitioning: false,
})

const usePageTransition = () => useContext(pageTransitionContext)

const PageTransitionProvider = ({
  offTimeout = 1500,
  children,
}: {
  children: React.ReactNode | React.ReactNode[]
  offTimeout?: number
}) => {
  const router = useRouter()
  const [isTransitioning, setIsTransitioning] = useState(false)

  useEffect(() => {
    router.events.on('routeChangeStart', startTransition)
    router.events.on('routeChangeComplete', endTransition)

    return () => {
      router.events.off('routeChangeStart', startTransition)
      router.events.off('routeChangeComplete', endTransition)
    }
  }, [])

  // useEffect(() => {
  //   if (process.env.NODE_ENV !== 'development') return

  //   function handleKeyPress(e) {
  //     if (e.key === 'l') toggleLoader()
  //   }

  //   window.addEventListener('keypress', handleKeyPress)

  //   return () => {
  //     window.removeEventListener('keypress', handleKeyPress)
  //   }
  // }, [])

  function startTransition() {
    setIsTransitioning(true)
  }

  function endTransition() {
    setTimeout(() => {
      setIsTransitioning(false)
    }, offTimeout)
  }

  function toggleLoader() {
    setIsTransitioning(prev => !prev)
  }

  const provided = { isTransitioning }

  return (
    <pageTransitionContext.Provider value={provided}>
      {children}
    </pageTransitionContext.Provider>
  )
}

export { usePageTransition, PageTransitionProvider }
