export const DEV = process.env.NODE_ENV === 'development'

export const MOBILE_QUERY = { maxWidth: 1024 }
export const TABLET_QUERY = { maxWidth: 1280 }

export const META_IMAGE = '/meta.jpg'
export const EMAIL_FIELD = 'emailAddress'
export const IG_FIELD = 'instagramHandle'

const META_TAGS = require('../public/metatags.json')
const TITLE = META_TAGS.name
const DESCRIPTION = META_TAGS.brand.shortDescription
const IMAGE_SRC = META_TAGS.brand.coverImage.image.url

export const GLOBAL_TAGS = [
  {
    tag: 'title',
    content: TITLE,
    attributes: null,
  },
  {
    tag: 'meta',
    content: null,
    attributes: { property: 'og:title', content: TITLE },
  },
  {
    tag: 'meta',
    content: null,
    attributes: { name: 'twitter:title', content: TITLE },
  },
  {
    tag: 'meta',
    content: null,
    attributes: { name: 'description', content: DESCRIPTION },
  },
  {
    tag: 'meta',
    content: null,
    attributes: { property: 'og:description', content: DESCRIPTION },
  },
  {
    tag: 'meta',
    content: null,
    attributes: { name: 'twitter:description', content: DESCRIPTION },
  },
  {
    tag: 'meta',
    content: null,
    attributes: { property: 'og:image', content: IMAGE_SRC },
  },
  {
    tag: 'meta',
    content: null,
    attributes: { name: 'twitter:image', content: IMAGE_SRC },
  },
  {
    tag: 'meta',
    content: null,
    attributes: { property: 'og:locale', content: 'en' },
  },
  {
    tag: 'meta',
    content: null,
    attributes: { property: 'og:site_name', content: TITLE },
  },
  {
    tag: 'meta',
    content: null,
    attributes: { property: 'og:url', content: 'https://capslockmagazine.eu' },
  },
  {
    tag: 'meta',
    content: null,
    attributes: { name: 'twitter:card', content: 'summary_large_image' },
  },
  {
    tag: 'meta',
    content: null,
    attributes: { name: 'viewport', content: 'width=device-width' },
  },
  {
    tag: 'meta',
    content: null,
    attributes: {
      name: 'facebook-domain-verification',
      content: '8js1fqhp4s0sf75hhovp5u6hem9h86',
    },
  },
]

export const FaviconTags = () => (
  <>
    <link
      rel="apple-touch-icon"
      sizes="57x57"
      href="/favicon/apple-icon-57x57.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="60x60"
      href="/favicon/apple-icon-60x60.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="72x72"
      href="/favicon/apple-icon-72x72.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="76x76"
      href="/favicon/apple-icon-76x76.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="114x114"
      href="/favicon/apple-icon-114x114.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="120x120"
      href="/favicon/apple-icon-120x120.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="144x144"
      href="/favicon/apple-icon-144x144.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="152x152"
      href="/favicon/apple-icon-152x152.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/favicon/apple-icon-180x180.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href="/favicon/android-icon-192x192.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/favicon/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="96x96"
      href="/favicon/favicon-96x96.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/favicon/favicon-16x16.png"
    />
    <link rel="manifest" href="/manifest.json" />
    <meta name="msapplication-TileColor" content="#000000" />
    <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
    <meta name="theme-color" content="#000000" />
    <meta
      name="apple-mobile-web-app-status-bar-style"
      content="black-translucent"
    />
  </>
)
