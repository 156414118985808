// import Footer from '@components/molecules/footer'
import { Transition, Variants, m } from 'framer-motion'
import { ReactNode } from 'react'

type Props = {
  variants?: Variants
  transition?: Transition
  children?: ReactNode
}

const DEFAULT_VARIANTS = {
  initial: {
    opacity: 1,
    transition: {
      type: 'ease',
    },
  },
  animate: {
    opacity: 1,
    transition: {
      type: 'ease',
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 1,
    transition: {
      type: 'ease',
      when: 'afterChildren',
      delay: 1,
    },
  },
}

export default function TransitionLayout({
  variants = DEFAULT_VARIANTS,
  transition,
  children,
}: Props) {
  return (
    <>
      <m.div
        layoutScroll
        initial="initial"
        animate="animate"
        exit="exit"
        variants={variants}
        transition={transition}
      >
        {children}
      </m.div>
    </>
  )
}
